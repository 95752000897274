import { SET_SELECTED_VENDOR, RESET_VENDOR } from '../actions/vendorActions';

const initialVendorState = {
  vendorId: null,
  vendorMobile: null,
  googleSheetId: null,
};

const vendorReducer = (state = initialVendorState, action) => {
  switch (action.type) {
    case SET_SELECTED_VENDOR:
      const { vendorId, vendorMobile, googleSheetId } = action.payload;

      // Store the vendor details in Redux only (do not use localStorage)
      return {
        ...state,
        vendorId,
        vendorMobile,
        googleSheetId,
      };

    case RESET_VENDOR: // Handle the RESET_VENDOR action
      // No need to touch localStorage here anymore
      return {
        ...state,
        vendorId: null,
        vendorMobile: null,
        googleSheetId: null,
      };

    default:
      return state;
  }
};

export default vendorReducer;
