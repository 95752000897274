import { LOGIN, LOGOUT } from '../actions/authActions';

const initialState = {
  user: null,
  token: localStorage.getItem('token') || null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      // Store token in localStorage, but user data should only be in Redux
      localStorage.setItem('token', action.payload.token);
      return { ...state, user: action.payload.user, token: action.payload.token };

    case LOGOUT:
      localStorage.removeItem('token');  // Only token removed from localStorage
      return { ...state, user: null, token: null };

    default:
      return state;
  }
};

export default authReducer;
