import { createStore, compose } from 'redux';
import rootReducer from './reducers/rootReducer';
import { decodeToken } from '../app/utils/token';
import { login } from './actions/authActions';
import { setSelectedVendor } from './actions/vendorActions';

// Conditionally apply Redux DevTools only in development
const composeEnhancers =
  process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

// Create the Redux store
const store = createStore(rootReducer, composeEnhancers());

// Rehydrate Redux state from the token in localStorage
const token = localStorage.getItem('token');
if (token) {
  const decodedUser = decodeToken(token); // Decode token to extract user details
  if (decodedUser) {
    // Dispatch login action to set user in Redux
    store.dispatch(login({ token, user: decodedUser }));

    // If vendor information is present in the decoded token, dispatch to Redux
    if (decodedUser.vendor_id && decodedUser.vendor_mobile && decodedUser.googleSheetId) {
      store.dispatch(setSelectedVendor(
        decodedUser.vendor_id,
        decodedUser.vendor_mobile,
        decodedUser.googleSheetId
      ));
    }
  }
}

export default store;
