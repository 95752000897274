import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import { useRoutes } from "react-router-dom";

// Lazy-loaded components
const Home = Loadable(lazy(() => import("./views/dashboard/ViewDashboard")));
const Permissions = Loadable(lazy(() => import("./views/pages/auth/Permissions")));
const Campaigns = Loadable(lazy(() => import("./views/pages/campaigns/CampaignsList")));
const TemplateMessage = Loadable(lazy(() => import("./views/pages/template-message/TemplateMessage")));
const CreateTemplateMessage = Loadable(lazy(() => import("./views/pages/template-message/CreateTemplateMessage")));
const Broadcast = Loadable(lazy(() => import("./views/pages/broadcast/Broadcast")));
const CustomerList = Loadable(lazy(() => import("./views/pages/customers/CustomersList")));
const UserActivity = Loadable(lazy(() => import("./views/pages/user_activity/UserActivity")));
const SubscriptionList = Loadable(lazy(() => import("./views/pages/subscription/SubscriptionList")));
const ViewSubscription = Loadable(lazy(() => import("./views/pages/subscription/ViewSubscription")));
const UserActivityDetails = Loadable(lazy(() => import("./views/pages/user_activity/UserActivityDetails")));
const ViewCustomer = Loadable(lazy(() => import("./views/pages/customers/ViewCustomer")));
const AddCustomer = Loadable(lazy(() => import("./views/pages/customers/AddCustomer")));
const EditCustomer = Loadable(lazy(() => import("./views/pages/customers/EditCustomer")));
const ViewChat = Loadable(lazy(() => import("./views/pages/chat/ChatView")));
const ChatFlow = Loadable(lazy(() => import("./views/pages/chat/ChatFlow")));
const ChatbotFlowchart = Loadable(lazy(() => import("./views/pages/chat/ChatbotFlowchart")));
const CustomChat = Loadable(lazy(() => import("./views/pages/chat/CustomChatTemplate")));
const CategoryList = Loadable(lazy(() => import("./views/pages/categories/CategoryList")));
const CreateCategory = Loadable(lazy(() => import("./views/pages/categories/CategoryCreateForm")));
const ViewCategory = Loadable(lazy(() => import("./views/pages/categories/ViewCategory")));
const VendorsList = Loadable(lazy(() => import("./views/pages/vendors/VendorsList")));
const CreateVendor = Loadable(lazy(() => import("./views/pages/vendors/VendorCreateForm")));
const ProductsList = Loadable(lazy(() => import("./views/pages/products/ProductsList")));
const ViewProduct = Loadable(lazy(() => import("./views/pages/products/ViewProduct")));
const OrdersList = Loadable(lazy(() => import("./views/pages/orders/OrdersList")));
const Test = Loadable(lazy(() => import("./views/pages/orders/Temp")));
const ViewOrder = Loadable(lazy(() => import("./views/pages/orders/ViewOrder")));
const CreateProduct = Loadable(lazy(() => import("./views/pages/products/AddProduct")));
const EditProduct = Loadable(lazy(() => import("./views/pages/products/EditProduct")));
const ViewProfile = Loadable(lazy(() => import("./views/pages/profile/ViewProfile")));
const EditProfile = Loadable(lazy(() => import("./views/pages/profile/EditProfile")));
const CartManage = Loadable(lazy(() => import("./views/pages/cart/CartManagement")));
const ViewCart = Loadable(lazy(() => import("./views/pages/cart/ViewCart")));
const NotFound = Loadable(lazy(() => import("./views/pages/auth/NotFound")));
const JwtLogin = Loadable(lazy(() => import("./views/pages/auth/JwtLogin")));
const ForgetPassword = Loadable(lazy(() => import("./views/pages/auth/ForgetPassword")));
const ResetPassword = Loadable(lazy(() => import("./views/pages/auth/ResetPassword")));
const SignUp = Loadable(lazy(() => import("./views/pages/auth/SignUp")));
const HandleGoogleRedirect = Loadable(lazy(() => import("./views/pages/products/HandleGoogleRedirect")));
const RoutesComponent = () => {
const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/dashboard", element: <Home /> },
      { 
        path: "/campaigns",
         element: <Campaigns />
      },{ 
        path: "/template-message/create",
         element: <CreateTemplateMessage />
      },{ 
        path: "/template-message",
         element: <TemplateMessage />
      },{ 
        path: "/profile/view",
         element: <AuthGuard 
         requiredAction={{ section: "Vendors" }}
         ><ViewProfile /></AuthGuard> 
      },{ 
        path: "/profile/edit",
        element: <AuthGuard 
        requiredAction={{ section: "Vendors" }}
        ><EditProfile /></AuthGuard> 
      },{ 
        path: "/subscription/list",
        element: <AuthGuard 
        requiredAction={{ section: "Subscription" }}
        ><SubscriptionList /></AuthGuard> 
      },{ 
        path: "/subscription/view/:id",
        element: <AuthGuard 
        requiredAction={{ section: "Subscription" }}
        ><ViewSubscription /></AuthGuard> 
      },{ 
        path: "/users/activities/list",
        element: <AuthGuard 
        requiredAction={{ section: "Activity" }}
        ><UserActivity /></AuthGuard> 
      },{ 
        path: "/users/activities/view/:id",
        element: <AuthGuard 
        requiredAction={{ section: "Activity" }}
        ><UserActivityDetails /></AuthGuard> 
      },{ 
        path: "/customers/list",
        element: <AuthGuard 
          requiredAction={{ section: "Customers" }} 
        ><CustomerList /></AuthGuard> 
      },{ 
        path: "/customer/create", 
        element: <AuthGuard 
        requiredAction={{ section: "Customers" }} 
        ><AddCustomer /></AuthGuard> 
      },{ 
        path: "/customer/edit/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Customers" }} 
        ><EditCustomer /></AuthGuard> 
      },{ 
        path: "/customer/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Customers" }} 
        ><ViewCustomer /></AuthGuard> 
      },{ 
        path: "/categories/list", 
        element: <AuthGuard 
        requiredAction={{ section: "Category" }} 
        ><CategoryList /></AuthGuard> 

      },{ 
        path: "/category/:mode/:id?", 
        element: <AuthGuard 
        requiredAction={{ section: "Category" }} 
        ><CreateCategory /></AuthGuard> 
      },{ 
        path: "/category/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Category" }}
        ><ViewCategory /></AuthGuard> 
      },{ 
        path: "/vendors/list", 
        element: <AuthGuard 
        requiredAction={{ section: "Vendors" }} 
        ><VendorsList /></AuthGuard> 
      },{ 
        path: "/vendor/:mode/:id?", 
        element: <AuthGuard 
        requiredAction={{ section: "Vendors"}} 
        ><CreateVendor /></AuthGuard> 
      },{
        path: "/products/list", 
        element: <AuthGuard 
        requiredAction={{ section: "Products" }}
        ><ProductsList /></AuthGuard>
      },{ 
        path: "/product/edit/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Products"}} 
        ><EditProduct /></AuthGuard> 
      },{ 
        path: "/product/create", 
        element: <AuthGuard 
        requiredAction={{ section: "Products"}} 
        ><CreateProduct /></AuthGuard> 
      },{ 
        path: "/product/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Products" }} 
        ><ViewProduct /></AuthGuard> 
      },{ 
        path: "/orders/list", 
        element: <AuthGuard 
        requiredAction={{ section: "Orders" }} 
        ><OrdersList /></AuthGuard> 
      },{ 
        path: "/test", 
        element: <AuthGuard 
        requiredAction={{ section: "Orders" }} 
        ><Test /></AuthGuard> 
      },{ 
        path: "/order/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Orders" }} 
        ><ViewOrder /></AuthGuard> 
      },{ 
        path: "/cart/manage", 
        element: <AuthGuard 
        requiredAction={{ section: "Cart" }} 
        ><CartManage /></AuthGuard> 
      },{ 
        path: "/cart/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Cart" }} 
        ><ViewCart /></AuthGuard> 
      },{ 
        path: "/chat/view", 
        element: <AuthGuard 
        requiredAction={{ section: "Chat" }} 
        ><ViewChat /></AuthGuard> 
      },{ 
        path: "/chat/template", 
        element: <AuthGuard 
        requiredAction={{ section: "Chat_Template" }} 
        ><CustomChat /></AuthGuard> 
      },{ 
        path: "/chat/flow", 
        element: <AuthGuard 
        requiredAction={{ section: "Intent" }} 
        ><ChatFlow /></AuthGuard> 
      },{ 
        path: "/chat/flowchart", 
        element: <AuthGuard
        requiredAction={{ section: "Intent" }} 
        ><ChatbotFlowchart /></AuthGuard> 
      },{ 
        path: "/broadcast", 
        element: <AuthGuard
        requiredAction={{ section: "Broadcast" }}
        ><Broadcast /></AuthGuard> 
      },{ 
        path: "/vendors/edit/permissions", 
        element: <AuthGuard
        requiredAction={{ section: "Role" }} 
        ><Permissions /></AuthGuard> 
      },{
        path: "/dashboard/default",
        element: <AuthGuard 
        ><Home /></AuthGuard>,
      }
    ],
  },
  { path: "/404", element: <NotFound /> },
  { path: "/signin", element: <JwtLogin /> },
  { path: "/signup", element: <SignUp /> },
  { path: "/forget-password", element: <ForgetPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/", element: <Navigate to="/dashboard" /> },
  { path: "*", element: <Navigate to="/dashboard" /> },
  { path: "/oauth2callback", element: <HandleGoogleRedirect /> },
];
return useRoutes(routes); // Returns the routes for rendering

}
export default RoutesComponent;
