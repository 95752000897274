export const SET_SELECTED_VENDOR = 'SET_SELECTED_VENDOR';
export const RESET_VENDOR = 'RESET_VENDOR';

export const setSelectedVendor = (vendorId, vendorMobile, googleSheetId) => ({
  type: SET_SELECTED_VENDOR,
  payload: { vendorId, vendorMobile, googleSheetId },
});

export const resetVendor = () => ({
  type: RESET_VENDOR, // Action to reset vendorId in Redux
});
