import CssBaseline from "@mui/material/CssBaseline";
import { MatxTheme } from "./components";
import SettingsProvider from "./contexts/SettingsContext";
import RoutesComponent from "./RoutesComponent"; // Import the new RoutesComponent
// import "../fake-db";

export default function App() {
  return (
    <SettingsProvider>
      <MatxTheme>
        <CssBaseline />
        <RoutesComponent /> {/* Use RoutesComponent instead of useRoutes(routes) */}
      </MatxTheme>
    </SettingsProvider>
  );
}
