import {jwtDecode} from 'jwt-decode';

/**
 * Decodes a JWT token.
 * @param {string} token - The JWT token to decode.
 * @returns {object|null} - The decoded payload or null if invalid.
 */
export const decodeToken = (token) => {
  try {
    return jwtDecode(token); // jwtDecode safely decodes the token payload
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

/**
 * Checks if the JWT token is still valid.
 * @param {string} token - The JWT token to check.
 * @returns {boolean} - True if valid, false otherwise.
 */
export const isTokenValid = (token) => {
  const decoded = decodeToken(token);
  if (!decoded || !decoded.exp) {
    return false;
  }
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  return decoded.exp > currentTime; // Token is valid if expiry is in the future
};
